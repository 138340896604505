import { Injectable } from '@angular/core';
import { UserService } from 'document-vault-ts-angular-client';

@Injectable({
  providedIn: 'root'
})
export class GroupsApiService {

  constructor(
    private userService: UserService
  ) { }

  creatGroups(userGroup) {
    return this.userService.addManagementUserGroup(userGroup);
  }

  getGroups(searchRequest) {
    return this.userService.getManagementUserGroups(searchRequest);
  }

  updateGroups(userGroup) {
    return this.userService.updateManagementUserGroup(userGroup);
  }

  deleteGroups(userGroupIds) {
    return this.userService.deleteManagementUserGroups(userGroupIds);
  }

  getManagementUsers(searchRequest) {
    return this.userService.getManagementUsers(searchRequest);
  }
}
